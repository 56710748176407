import React from "react"
import styled from "styled-components"

const SpacerDiv = styled.div`
height:${props => (props.height == null) ? (100):(props.height)}px;
`

export default function Spacer(props){
    return(
        <SpacerDiv height={props.height}></SpacerDiv>
    );
}