import React from "react"
import styled from "styled-components"
import Responsive from "../../data/icons/responsive_content_blue.svg"
import Sliders from "../../data/icons/sliders_blue.svg"
import Speed from "../../data/icons/speed_blue.svg"


const Container = styled.div`
position:relative;
width:100%;

display:-ms-grid;

display:grid;
-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

& h2{    
    font-size:2em;
    text-align:center;
}

& *:nth-child(2){-ms-grid-column:1;-ms-grid-column-span:12;grid-column:1/13;}
& *:nth-child(1){-ms-grid-column:1;-ms-grid-column-span:12;grid-column:1/13;}

@media(min-width:601px){
    & *:nth-child(2){-ms-grid-column:2;-ms-grid-column-span:10;grid-column:2/12;}
}

@media(min-width:993px){
    & *:nth-child(2){-ms-grid-column:3;-ms-grid-column-span:8;grid-column:3/11;}

    &h2{
        font-size:2.25em;
    }
}
`

const SubitemContainer = styled.div`
position:relative;
width:100%;

display:-webkit-box;

display:-ms-flexbox;

display:flex;
-ms-flex-pack:distribute;
    justify-content:space-around;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;

& div:nth-child(2){
    & svg{
        width: 85px;
    }

    @media(min-width:601px){
        & svg{
            width: 90px;
        }
    }

    @media(min-width:993px){
        & svg{
            width: 175px;
        }
    }
}
`

const Subitem = styled.div`
position:relative;
width:80%;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
    -ms-flex-direction:column;
        flex-direction:column;
text-align:center;
margin:1em 0;

& h3{
    line-height: 20px;
}

@media(min-width:1100px){
    width:25%;
}
`

const ImageContainer = styled.div`
width:100%;
height:100px;

display:-webkit-box;

display:-ms-flexbox;

display:flex;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;

& svg{
    height: 95%;
}

@media(min-width:993px){
    height:200px;
    & svg{
        height: 90%;
    }
}
`


export default function WebsiteSubitems(props){
    return(
        <Container id={props.id}>
            <h2>{props.data.title}</h2>
            <SubitemContainer>
                {
                    props.data.Subitems.map((item, i) => (
                        <Subitem key={i}>
                            <ImageContainer>
                                { i === 0 && <Responsive/> }
                                { i === 1 && <Sliders/> }
                                { i === 2 && <Speed/> }
                            </ImageContainer>
                            <div>
                                <h3>{item.title}</h3>
                                <p dangerouslySetInnerHTML={{ __html: item.text }}/>
                            </div>
                        </Subitem>
                    ))
                }
            </SubitemContainer>
        </Container>
    )
}