import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/Layout"
import WebsitesSubitems from "../components/WebsitesSubitems"
import TopBanner from "../components/TopBanner"
import WhoAreWe from "../components/WhoAreWe"
import WhyDigital from "../components/WhyDigital"
import Spacer from "../components/Spacer"
import BottomBanner from "../components/BottomBanner"

export default function Index({data}) {
  const global = data.allGlobalJson.nodes[0];
  const indexJson = data.allIndexJson.nodes[0]
  const whoAreWe = indexJson.WhoAreWe
  const whyDigital = indexJson.WhyDigital
  const bottomBanner = indexJson.BottomBanner
  const ourQualities = indexJson.OurQualities

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8"/>
        <meta name="google-site-verification" content="pFflktxH4rtjo5ZDiAf_5ugd7kQtU7lggMDCNCBOIJI" />
        <title>Kudlik Pfeifer - Webdesign - Kirchen und Betzdorf</title>
        <meta name="description" content="Kudlik und Pfeifer ist ein Start-Up aus der Region Betzdorf und Kirchen das sich auf das Designen und Erstellen von individuellen Internetseiten und dem Entwerfen von digitalen Lösungen spezialisiert hat. Ob Homepage oder IT-System wir machen Sie fit für die Zukunft."/>
        <html lang="de"/>
      </Helmet>
      <Layout id="top">
          <TopBanner texts={[global.Slogan1,global.Slogan2]}/>
          <div style={{width:"100%", height:"120px"}}/>
          <WhoAreWe id="about" data={whoAreWe}/>
          <Spacer height="200"/>
          <WhyDigital id="digital" data={whyDigital}/>
          <WebsitesSubitems id="qualitaeten" data={ourQualities}/>
          <Spacer/>
          <BottomBanner button={bottomBanner.button} text={global.Slogan3}/>
      </Layout>
    </div>
  )
}


export const query = graphql`
{
  allIndexJson{
    nodes{
      WhoAreWe{
        title
        text
      }
      WhyDigital{
        title1
        title2
        text
      }
      OurQualities
      {
        title
        Subitems{
          title
          text
        }
      }
      BottomBanner{
        button {
          href
          text
        }
      }
    }
  }
  allGlobalJson{
    nodes{
      Slogan1
      Slogan2
      Slogan3
    }
  }
}
`
