import React from "react"
import styled from "styled-components"


const Container = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-align:center;
    -ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
    -ms-flex-direction:column;
        flex-direction:column;
padding-top:100px;
text-align:center;

& h2{
    position:relative;
    font-size:1.8em;
    line-height: 20px;

    &:after{
        content: '';
        position: absolute;
        left: 30%; 
        bottom: -5px;
        height: 3px;
        width:40%;
        background-color: var(--tmain);
        border-radius:200px;
    }
}

& p{
    max-width:80%;
    line-height: 30px;
}

@media(min-width:993px){
    & h2{
        font-size:2.25em;
    }

    & p{
        max-width:40%;
    }
}
`

export default function WhoAreWe(props){
    return(
        <Container id={props.id}>
            <h2>
                {props.data.title}
            </h2>
            <p>
                {props.data.text}
            </p>
        </Container>
    )
}