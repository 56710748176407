import React from "react"
import styled from "styled-components"
import TopSlogan from "../../data/icons/TopSlogan.svg"
import Top from "../../data/icons/TopBanner.svg"
import SmallTop from "../../data/icons/bluelineTop2.svg"
import Website from "../../data/icons/TopWebpage.svg"

const Container = styled.div`
position:relative;
width:100%;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
    -ms-flex-direction:column;
        flex-direction:column;
-webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;
-webkit-box-align:center;
    -ms-flex-align:center;
        align-items:center;

& >svg {
    position:relative;
    width:80%;
    height:auto;
    margin-top:7%;

    @media(min-width:601px){
        margin-top:5%;
        width:60%;
    }
    
    @media(min-width:993px){
        position:absolute;
        top:10%; 
        right:10%;
        width:30%;
    }
}
`
const MobileBG = styled.div`
position:absolute;
top:0px;
height:50%;
width:100%;
background-color:var(--tmain);

& svg{
    position:absolute;
    top:98%;
    left:0;
    width:100%;
    height:auto;
    display:block;
    z-index:-1;
}

@media (min-width:993px) {
    display:none;
}
`

const Bg = styled.div`
display:none;
width:100%;

@media (min-width:993px) {
    display:block;
    
    & svg{
        width:100%;
        height:auto;
        display: block;
    }
}
`

const Pic = styled.div`
position:relative;
width:80%;
margin-top:8%;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;

@media (min-width:993px) {
    margin-top:0%;
    position:absolute;  
    width:40%;
    height:auto;
    left:5%;
}
& svg {
    width:100%;
    height:100%;
}
`

export default function TopBanner(props){
    return(
        <Container>
            <MobileBG>
                <SmallTop/>
            </MobileBG>
            <Bg>
                <Top/>
            </Bg>
            <Pic>
                <Website/>
            </Pic>
            <TopSlogan/>
        </Container>
    )
}