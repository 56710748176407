import React from "react"
import styled from "styled-components"
import TopSVG from "../../data/icons/WhyDigitalBackgroundTop3.svg"
import BotSVG from "../../data/icons/WhyDigitalBackgroundBot3.svg"
import Spaceship from "../../data/icons/Spaceship.svg"

const Wrapper = styled.div`
position:relative;

& svg {
    position:relative;
    width:100%;
    height:auto;
    display:block;
}

& svg:nth-child(1){
    left:0;
    top:1.35vw;
    z-index:-1;
}

& svg:nth-child(3){
    right:0;
    bottom:1.35vw;
    z-index:-1;
}

& svg:nth-child(4){
    position:absolute;
    display:block;
    -webkit-transform-origin:center;
    -ms-transform-origin:center;
        transform-origin:center;
    -webkit-transform:rotateZ(37deg);
    -ms-transform:rotate(37deg);
        transform:rotateZ(37deg);

    left:0;
    top:-10%;
    width:34%;

    @media (min-width:601px) {
    top:-5%;
    width:24%;
    }

    @media (min-width:993px) {
    top:-10%;
    width:20%;
    }
}
`

const Container = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-align:center;
    -ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
    -ms-flex-direction:column;
        flex-direction:column;

text-align:center;
background-color:var(--tmain);
color:var(--tsec);
margin:0;
padding: 4em 0;


& h2{
    position:relative;
    font-size:2.25em;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    display:inline-block;
}

& h2:nth-child(1){
    color:var(--tsec);
}

& h2:nth-child(2){
    margin-left:10px;
}

& p{
    max-width:80%;
    color:var(--tsec);
}

@media(min-width:993px){
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    & div{
        min-width:25%;
    }

    & h2{
        font-size:2.25em;
    }

    & p{
        max-width:40%;
    }
}
`

export default function WhyDigital(props){
    return(
        <Wrapper id={props.id}>
            <TopSVG/>
            <Container>
                <div>
                    <h2>{props.data.title1}</h2>
                    <h2>{props.data.title2}</h2>
                </div>
                <p>
                    {props.data.text}
                </p>
            </Container>
            <BotSVG/>
            <Spaceship/>
        </Wrapper>
        
    )
}